//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
.top-view {
  position: relative;
  background: #171717;
  padding-left: 5%;
  height: 830px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: url("../../img/topBackground.jpg") no-repeat;
  background-size: 1440px 826px;
  background-position: right center;

  .background-gradient {
    position: absolute;
    left: max(calc(100vw - 1440px), -1px);
    top: 0;
    height: 100%;
    width: calc(1440px/3*2);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }

  .background-black{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100vw - 1440px);
    background: black;
  }

  .top-view-info-block {
    position: absolute;
    width: 500px;
    //height: 440px;

    .main-title {
      width: 490px;
      height: 180px;
      //font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .main-text {
      width: 370px;
      height: 140px;
      //font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #FFFFFF;
      margin-bottom: 40px;
    }
  }

  .top-view-photo-group {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    right: calc(60px);
    max-width: 100%;
    width: 700px;
    height: 620px;

    &:hover{
      .phone-container {
        //transform: scale(1.05);
      }

      .flash {
        transform: scale(1.1);
      }

      .star {
        transform: scale(1.1);
      }

      .letter {
        transform: scale(1.1);
      }
    }

    .phone-container {
      overflow: hidden;
      //background: url("../../img/iPhone_12_Pro_transparent.svg") no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      transform: translateX(5px) scale(1);
      transition: all 0.3s 0.1s;
      max-width: 90%;
      position: absolute;
      bottom: 50px;
      width: 280px;
      height: 600px;
      box-sizing: border-box;
      z-index: 3;
      padding-bottom: 48px;

      .iphone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
      }

      .tab-bar{
        position: relative;
        width: 95%;
        border-top: 1px solid #EBEBEB;
      }

      .bottom-white{
        position: relative;
        width: 90%;
        height: 28px;
        background: white;
        //border-radius: 0 0 30px 30px;
        display: flex;
        justify-content: center;
      }

      .bottom-white::after{
        content: "";
        position: absolute;
        display: block;
        height: 5px;
        width: 90px;
        bottom: 9px;
        border-radius: 2.5px;
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .arrow {
      position: absolute;
      max-width: 50%;
      width: 74px;
      height: 35px;
      bottom: 200px;
      left: 80px;
    }

    .arrow-text {
      position: absolute;
      max-width: 40%;
      width: 73px;
      height: 20px;
      bottom: 250px;
      left: 40px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      transform: rotate(-17deg);
    }

    .flash {
      transition: all 0.3s 0.2s;
      position: absolute;
      max-width: 40%;
      width: 180px;
      height: 180px;
      bottom: 100px;
      right: 25px;
    }

    .star {
      transition: all 0.3s;
      position: absolute;
      max-width: 50%;
      width: 210px;
      height: 210px;
      right: 90px;
      top: 10%;
    }

    .letter {
      transition: all 0.3s;
      position: absolute;
      max-width: 90%;
      top: -20px;
      left: 40px;
      width: 250px;
      z-index: 10;
    }

    .ellipse {
      position: absolute;
      bottom: 0;
      z-index: 0;
    }
  }

  .blur {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 23, 23, 0.7);
    filter: blur(15vw);
    backdrop-filter: blur(15vw);

    .circle1 {
      position: absolute;
      border-radius: 50%;
      width: 25vw;
      height: 25vw;
      left: -5vw;
      bottom: -5vh;
      background: linear-gradient(78.11deg, rgba(253, 29, 29, 0.6) -59.98%, rgba(252, 176, 69, 0.6) 113.19%);
      z-index: -1;
    }

    .circle2 {
      position: absolute;
      border-radius: 50%;
      width: 30vw;
      height: 30vw;
      right: 15vw;
      top: -10vh;
      background: linear-gradient(78.11deg, #FD1D1D -59.98%, #FCB045 113.19%);
      opacity: 0.8;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 1300px) {
  .top-view {
    background-position: right max(calc(100vw - 1300px), -200px) top 0;

    .top-view-photo-group {
      right: max(calc(100vw - 1300px + 60px), -140px);

      .letter {
        width: 220px;
        left: 70px;
      }

      .flash {
        right: 70px;
        z-index: 10;
        bottom: 80px;
      }
    }

    .top-view-info-block {
      .main-title {
        width: 400px;
      }
    }
  }
}


@media screen and (max-width: 900px) {
  .top-view {
    height: 1300px;
    overflow: hidden;
    max-height: unset;
    background-position: bottom -50px right -300px;
    background-size: auto 1000px;

    .background-black{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(1300px - 950px);
      background: #171717;
    }

    .background-gradient {
      position: absolute;
      left: 0;
      top: calc(1300px - 950px);
      height: calc(1000px/2);
      width: 100%;
      background: linear-gradient(#171717 0%, rgba(0,0,0,0) 100%);
    }

    .top-view-info-block {
      width: 90%;
      left: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-title {
        width: 335px;
        font-size: 36px;
      }

      .main-text {
        width: 335px;
        font-size: 16px;
        margin-bottom: 60px;
      }
    }

    .top-view-photo-group {
      left: 5%;
      width: 90%;

      .letter {
        width: 220px;
        left: -70px;
      }

      .arrow {
        transform: matrix(-0.77, 0.64, 0.64, 0.77, 0, 0);
        top: -100px;
        left: calc(50% + min(100px, 20%));
      }

      .arrow-text {
        top: -160px;
        left: calc(50% + min(110px, 22%));
      }

      .star {
        width: 189px;
        height: 189px;
        right: -50px;
      }

      .flash {
        right: -10px;
        z-index: 10;
        bottom: -10px;
      }
    }

    .top-view-info-block {
      top: 100px;
    }
  }
}

@media screen and (max-width: 350px) {
  .top-view {
    .top-view-info-block {
      width: 90%;
      left: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-title {
        width: 310px;
        font-size: 36px;
      }

      .main-text {
        width: 335px;
        font-size: 16px;
        margin-bottom: 60px;
      }
    }

    .top-view-photo-group {
      min-width: 310px;

      .phone-container{
        left: 0;
      }

      .letter {
        width: 220px;
        left: -150px;
      }
    }

    .top-view-info-block {
      top: 100px;
    }
  }
}
