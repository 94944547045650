.achievement-section {
  padding-left: 5%;
  position: relative;
  height: 800px;
  background: url("../../../img/slimeBackground.svg") no-repeat right top;
  background-size: max(100vw, 1920px) 100%;


  .ellipse{
    position: absolute;
    top: 0;
    left: 0;
  }

  .first-ellipse {
    z-index: -1;
    position: absolute;
    width: 484px;
    height: 484px;
    left: -130px;
    top: 300px;
    background: linear-gradient(78.11deg, rgba(253, 29, 29, 0.5) -59.98%, rgba(252, 176, 69, 0.5) 113.19%);
    opacity: 0.3;
    border-radius: 50%;
  }


  .achievement-info-block {
    position: absolute;
    top: 258px;
    .achievement-title {
      width: 480px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: white;
      margin-bottom: 20px;
    }

    .achievement-text {
      z-index: 2;
      width: 480px;
      height: 96px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: white;
      margin-bottom: 40px;
    }
  }

  .achievement-photo-group {
    position: absolute;
    width: 810px;
    height: 515px;
    right: 0;
    top: 145px;

    &:hover{
      .confetti{
        transform: scale(1);
      }
    }

    .first-achievement {
      position: absolute;
      z-index: 10;
      left: 180px;
      top: 90px;
      transform: rotate(-13deg);
      width: 250px;
      border-radius: 20px;
      box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.1);
    }

    .second-achievement {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 220px;
      transform: rotate(10deg);
      width: 250px;
      border-radius: 20px;
      box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.1);
    }

    .third-achievement {
      position: absolute;
      z-index: 2;
      right: 110px;
      top: 180px;
      transform: rotate(6deg);
      width: 250px;
      border-radius: 20px;
      box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.1);
    }

    .confetti {
      transition: all 0.3s;
      transform: scale(0.9);
      position: absolute;
      z-index: 100;
    }
  }

@media screen and (max-width: 1200px) {
  .achievement-info-block {
    top: 220px;
  }
  .achievement-photo-group {
    width: 450px;
    top: 250px;

    .confetti {
      width: 500px;
      right: 0;
    }

    .first-achievement {
      top: 20px;
      left: 20px;
      width: 200px;
    }

    .second-achievement {
      right: 100px;
      top: -70px;
      width: 200px;
    }

    .third-achievement {
      right: 20px;
      top: 130px;
      width: 200px;
    }
  }
}
}

@media all and (max-width: 900px) {
  .achievement-section {
    height: 1200px;

    .achievement-photo-group {
      top: 200px;
      left: calc(50% - 335px/2);

      width: 335px;

      .confetti {
        width: 450px;
        left: calc(50% - 450px/2);
      }
    }

    .achievement-info-block {
      width: 335px;
      left: calc(50% - 335px/2);
      top: 600px;
      .achievement-title {
        width: 335px;
        text-align: left;
        margin-bottom: 60px;
      }

      .achievement-text {
        width: 335px;
        margin-bottom: 60px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .achievement-section {
    .achievement-info-block {
      width: 90%;
      left: 5%;
    }
  }
}