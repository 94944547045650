.button {
  //width: 190px;
  //height: 56px;
  //background-image: url("../../../img/appstore.svg");
  background-color: rgba(0,0,0, 0);
  //background-size: 190px 56px;
  //background-repeat: no-repeat;
  border-radius: 6px;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  transform: translateX(-12px) translateY(-10px);


  :hover{
    feBlend{
      display: none;
    }
  }


}