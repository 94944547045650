.tasks-section {
  position: relative;
  height: 56vw;
  background: #06070B;
  background-size: contain;
  display: flex;
  align-items: center;

  .image-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;

    img{
      max-height: 95%;
    }
  }

  .tasks-info-block {
    position: relative;
    width: 100%;
    flex-shrink: 2;
    //height: 320px;
    .tasks-title {
      width: 380px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: white;
      margin-bottom: 40px;
    }

    .tasks-text {
      width: 480px;
      max-width: 95%;
      height: 96px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: white;
      margin-bottom: 20px;
    }
  }

  .tasks-photo-group {
    position: absolute;
    height: 650px;
    width: 780px;
    top: 100px;
    .camera {
      position: absolute;
      top: 0;
      left: 20px;
      z-index: 2;
    }

    .tasks-iphone {
      position: absolute;
      left: 260px;
      top: 65px;
      height: 650px;
      z-index: 1;
    }

    .first-photo {
      position: absolute;
      top: 280px;
      left: 60px;
      z-index: 2;
    }

    .second-photo {
      right: 0;
      position: absolute;
      z-index: 2;
    }
  }

  @media screen and (max-width: 1400px) {
    .tasks-photo-group {
      width: 650px;
      .tasks-iphone {
        width: 250px;
        height: unset;
        left: 220px;
      }

      .camera {
        width: 300px;
      }

      .first-photo {
        width: 250px;
        left: 40px;
      }

      .second-photo {
        width: 260px;
        right: 0;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .tasks-section {
    height: calc(100vw + 400px);
    flex-direction: column;
    align-items: center;

    img{
      max-width: 110vw;
    }

    .tasks-info-block {
      width: 335px;

      .tasks-title {
        width: 335px;
        text-align: left;
        margin-bottom: 70px;
      }

      .tasks-text {
        width: 335px;
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .tasks-section {
    .tasks-info-block {
      width: 90%;
    }
  }
}