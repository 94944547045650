.game-mode {
  position: relative;
  height: 900px;

  .first-ellipse {
    z-index: -1;
    position: absolute;
    width: 285px;
    height: 285px;
    left: 1130px;
    top: 110px;
    background: linear-gradient(78.11deg, #FD1D1D -59.98%, #FCB045 113.19%);
    opacity: 0.2;
    border-radius: 50%;
  }

  .second-ellipse {
    position: absolute;
    width: 780px;
    height: 780px;
    left: -70px;
    top: 200px;
    background: linear-gradient(78.11deg, #FD1D1D -59.98%, #FCB045 113.19%);
    opacity: 0.3;
    z-index: -1;
    border-radius: 50%;
  }

  .group-of-photos {
    position: absolute;
    height: 750px;
    width: 750px;
    z-index: 0;
    left: min(calc(100vw - 1300px), 0px);

    &:hover{
      .iphone-ski{
        transform: translateY(-50px) rotate(-2deg);
      }

      .iphone-table{
        transform: translateY(-40px) rotate(2deg);
      }
    }

    .iphone-ski {
      transition: all 0.3s 0.05s;
      position: absolute;
      width: 285px;
      left: 180px;
      top: 140px;
      transform: rotate(-8.5deg);
      z-index: 6;
    }

    .iphone-table {
      transition: all 0.3s;
      position: absolute;
      width: 285px;
      right: 40px;
      top: 75px;
      transform: rotate(4deg);
      z-index: 5;
    }

    .number-one {
      position: absolute;
      width: 185px;
      height: 240px;
      left: 40px;
      top: 240px;
      z-index: 4;
    }

    .number-two {
      position: absolute;
      width: 185px;
      height: 240px;
      right: 0;
      bottom: 0;
      z-index: 4;
    }
  }

  .info-block {
    position: absolute;
    top: 260px;
    right: 0;
    width: 600px;
    height: 440px;

    .game-mode-title {
      width: 480px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: #171717;
      margin-bottom: 40px;
    }

    .game-mode-text {
      width: 480px;
      height: 96px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1400px){
    .info-block {
      width: 400px;
      right: 10%;
    }

    //.group-of-photos {
    //  width: 650px;
    //
    //  .number-one {
    //    left: 0;
    //  }
    //
    //  .iphone-ski {
    //    left: 80px;
    //  }
    //}
  }
}

@media all and (max-width: 900px){
  .game-mode {
    height: 1150px;
    //overflow: hidden;
    width: 90%;
    left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;

    .info-block {
      width: 335px;
      top: 700px;
      right: unset;
      .game-mode-title {
        width: 335px;
        text-align: left;
        margin-bottom: 60px;
        margin-left: 0;
      }

      .game-mode-text {
        width: 335px;
        text-align: left;
        margin-bottom: 60px;
      }
    }

    .group-of-photos {
      width: 375px;
      left: calc(50% - 375px/2 - 35px);

      .iphone-ski {
        width: 250px;
        //height: 500px;
        left: 0;
      }

      .iphone-table {
        width: 250px;
        //height: 500px;
        right: -50px;
      }

      .number-one {
        width: 220px;
        height: 240px;
        top: 190px;
        left: -100px;
      }

      .number-two {
        width: 220px;
        height: 240px;
        bottom: 110px;
        left: 240px;
      }
    }
  }
}


@media screen and (max-width: 350px) {
  .game-mode {
    .group-of-photos {
      width: 90%;
      left: 5%;
    }
  }
}

