.main-view {
  position: relative;
  //overflow: hidden;
  //filter: blur(15vw);
  //backdrop-filter: blur(15vw);


  .background-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(230px);
    //overflow: hidden;
    //backdrop-filter: blur(15vw);
    //background-color: rgba(255, 255, 255, 0.8);

    width: 100%;
    height: 100%;

    .background{
      //background-image: url("../../img/bg.svg");
      background-size: 151% 111%;
      background-position: 82% 11%;
      background-origin: border-box;
      width: 100%;
      height: 100%;
    }
  }

  .blur{
    filter: blur(15vw);
    backdrop-filter: blur(15vw);
    width: 100%;
    height: 100%;
  }

  .group-of-ellipse {
    filter: blur(15vw);
    backdrop-filter: blur(15vw);

    .ellipse3 {
      position: absolute;
      width: 484px;
      height: 484px;
      right: 60px;
      top: 70px;
      border-radius: 50%;
      z-index: 10;
      //background: linear-gradient(78.11deg, rgba(253, 29, 29, 0.5) -59.98%, rgba(252, 176, 69, 0.5) 113.19%);
    }
  }
}