header{
  z-index: 100;
  width: fit-content;
  position: absolute;
  left: 5%;
  top: 27px;

  img{
    width: 142px;
  }
}