.profile-section {
  position: relative;
  height: 900px;
  padding-left: 5%;

  .profile-photo {
    position: absolute;
    height: 480px;
    left: 130px;
    top: 175px;
  }

  .profile-info-block {
    position: absolute;
    height: 440px;
    width: 580px;
    top: 200px;
    right: 5%;
    .overhead {
      width: 150px;
      height: 22px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FD6230;
      margin-bottom: 16px;
    }

    .profile-title {
      width: 580px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: #171717;
      margin-bottom: 20px;
    }

    .profile-text {
      width: 580px;
      height: 72px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #8C8C8C;
      margin-bottom: 32px;
    }

    .first-subtitle {
      width: 580px;
      height: 49px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: #CCCCCC;
      z-index: 2;
      margin-bottom: 24px;
    }

    .second-subtitle{
      width: 580px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      color: #CCCCCC;
      z-index: 2;
    }
  }

  @media screen and (max-width: 1400px) {
    .profile-photo {
      width: 380px;
      height: 380px;
      left: 5%;
      top: 230px;
    }
  }
}

@media all and (max-width: 1200px) {
  .profile-section {
    .profile-info-block {
      max-width: 40%;
      .profile-title {
        font-size: 30px;
        max-width: 100%;
      }

      .profile-text {
        font-size: 18px;
        max-width: 100%;
      }

      .first-subtitle {
        font-size: 30px;
        max-width: 100%;
      }

      .second-subtitle {
        font-size: 30px;
        max-width: 100%;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .profile-section {
    height: 900px;

    .profile-info-block {
      left: calc(50% - 335px/2);
      top: 40px;
      .profile-title {
        max-width: unset;
        width: 335px;
        right: 20px;
        margin-bottom: 20px;
      }

      .profile-text {
        max-width: unset;
        width: 335px;
        margin-bottom: 80px;
      }

      .first-subtitle {
        max-width: unset;
        width: 335px;
        margin-bottom: 70px;
      }

      .second-subtitle {
        max-width: unset;
        width: 335px;
      }
    }

    .profile-photo {
      top: 350px;
      left: calc(50% - 350px/2);
      width: 350px;
      height: 350px;
    }
  }
}


@media screen and (max-width: 350px) {
  .profile-section {
    .profile-info-block {
      width: 90%;
      left: 5%;
      max-width: unset;

      .profile-title {
        width: 100%;
      }

      .profile-text {
        width: 100%;
      }
    }

    .profile-photo {
      width: 90%;
      left: 5%;
    }
  }
}