.Task {
  position: relative;
  z-index: 4;
  //top: 37px;
  //left: 16px;
  text-align: center;
  width: calc(100% - 17px - 17px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 448px;
  overflow: hidden;
  //background: black;
}

.phone {
  transition: all 0.3s 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 428px;
  width: 100%;
  max-height: 926px;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  img {
    width: 88%;
  }

  .bottom-panel {
    overflow: hidden;
    transition: all 1s 1.2s;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: white;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    transform: translateY(min(70%, calc(100% - 150px)));
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.final{
      transform: translateY(0);
      //border-radius: 0;
    }

    .score {
      transition: all 1s 1.2s;
      margin-top: 16px;
      font-size: 40px;
      overflow: hidden;
      transform: scale(1);
      color: #ababab;
      opacity: 1;
      font-weight: bolder;

      &.final{
        transform: scale(1.4) translateY(50px);
        margin-top: calc(4% + 90px + 20px);
        //opacity: 0;
      }
    }

    .emojiContainer {
      overflow: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .image{
      transition: all 1s 1.2s;
      position: absolute;
      bottom: 100%;
      height: 90px;

      &.final{
        bottom: calc(100% - 90px - 4%);
      }
    }

    .appStoreButton{
      transition: all 1s 1.2s;
      cursor: pointer;
      position: absolute;
      bottom: 3%;
      background: url("../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg") no-repeat;
      background-size: contain;

      width: 216px;
      height: calc(216px/3);
      left: calc(50% - 216px/2);

      opacity: 0;

      &.final{
        opacity: 1;
      }
    }

    .score-message-title {
      transition: all 0.5s, transform 1s 1.2s;
      overflow: hidden;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 25px;
      font-weight: bold;
      display: flex;
      align-items: center;
      opacity: 0;
      transform: scale(0.8);

      &.final{
        transform: scale(1) translateY(-60px);
        opacity: 1;
      }
    }

    .score-message-subtitle {
      transition: all 0.5s, transform 1s 1.2s;
      overflow: hidden;
      margin-top: 4px;
      margin-bottom: 20px;
      font-size: 16px;
      //font-weight: bold;
      display: flex;
      align-items: center;
      opacity: 0;
      transform: scale(0.8);

      &.final{
        transform: scale(1) translateY(-60px);
        opacity: 1;
      }
    }

    .bottomContainer {
      background: white;
      transition: all 0.4s ease-in;
      position: absolute;
      top: 0;
      overflow: hidden;
      width: 100%;
      height: max(30%, 150px);
      box-sizing: border-box;
      border-radius: 20px;

      &.hidden {
        top: 100%;
      }
    }

    .text {
      overflow: hidden;
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;

      .currentGap {
        margin: 4px;
        color: gray;
      }

      .futureGap {
        margin: 4px;
      }

      .pastGap {
        margin: 4px;
        color: #FF4848;

        &.correct {
          color: #51C871;
        }
      }
    }

    .buttons {
      transition: all 0.8s;
      overflow: hidden;
      margin-top: 16px;
      width: 90%;
      margin-left: 5%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .horizontal {
        position: relative;
        display: flex;
        width: 100%;
        height: 45%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;

        .phone-button {
          transition: all 0.2s;
          outline: none;
          background: white;
          width: 48%;
          font-size: 100%;
          font-weight: bold;
          height: 100%;
          border: 1px solid gray;
          border-radius: 10px;

          &:active {
            color: black;
          }

          &.clicked {
            color: white;
            background: #FF4848;
          }

          &.answer {
            &.clicked {
              background: #51C871;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 428px) and (max-height: 926px) {
  .phone {
    border-radius: 0px;
    border: none;
  }
}