.footer {
  position: relative;
  height: 460px;
  background-color: #171717;
  max-width: 100vw;

  .links-flex{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-group-photo {
    width: 100vw;
    height: 460px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .ellipse {
      position: absolute;
      top: -140px;

    }

    .footer-icon {
      position: absolute;
      top: 42px;
      left: calc(50% - 96px / 2);
      width: 96px;
    }

    .footer-picklang {
      position: absolute;
      top: 165px;
      left: calc(50% - 142px / 2);
    }

    .footer-text {
      position: absolute;
      top: 260px;
      width: 335px;
      left: calc(50% - 335px / 2);
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
    }

    .button {
      position: absolute;
      transform: unset;
      bottom: 80px;
      left: calc(50% - 207px / 2);
    }

    .copyright {
      margin-right: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #8C8C8C;

      &:first-child{
        margin-left: 0;
        flex-grow: 6;
      }
      &:last-child{
        margin-right: 0;
        flex-grow: 6;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .footer {
    .footer-group-photo {
      .footer-text {
        top: 240px;
        width: 310px;
        left: calc(50% - 310px / 2);
      }
    }
  }
}