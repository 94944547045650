.categories-section {
  position: relative;
  height: 900px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ellipse {
    position: absolute;
    width: 700px;
    height: 700px;
    top: 400px;
    left: 780px;
    background: linear-gradient(78.11deg, #FD1D1D -59.98%, #FCB045 113.19%);
    opacity: 0.2;
    z-index: -1;
    border-radius: 50%;
  }

  .scroll-block {
    position: absolute;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100vw;
    top: 220px;

    img{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 1511px;
    }
  }

 .categories-info-block {
    position: absolute;
    top: 0px;

   .categories-title {
     width: 468px;
     font-family: 'Montserrat', sans-serif;
     font-style: normal;
     font-weight: 600;
     font-size: 40px;
     line-height: 40px;
     text-align: center;
     color: #171717;
   }

   .categories-text {
     position: absolute;
     width: 468px;
     height: 48px;
     font-family: 'Montserrat', sans-serif;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 150%;
     text-align: center;
     color: #8C8C8C;
   }
 }

  @media screen and (max-width: 1400px) {
    .scroll-block {

      .card-photo {
        width: 230px;
        height: 324px;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .categories-section {
    height: 900px;

    .categories-info-block {
      width: 90%;
      position: absolute;
      .categories-title {
        width: 335px;
        margin-left: calc(50% - 335px/2);
        text-align: left;
        margin-bottom: 40px;
      }

      .categories-text {
        width: 335px;
        left: calc(50% - 335px/2);
        text-align: left;
      }
    }

    .scroll-block {
      top: 250px;
    }
  }
}

@media screen and (max-width: 350px) {
  .categories-section {
    .categories-info-block {
      width: 90%;
      left: 5%;

      .categories-title {
        margin-left: unset;
      }

      .categories-text {
        left: 0;
      }
    }
  }
}