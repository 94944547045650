.challenges-section {
  position: relative;
  height: 1000px;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .double-ellipse{
    position: absolute;
    top: 0;
    left: 20%;
    width: 73%;
  }

  .challenges-info-block {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .challenges-title {
      width: 502px;
      height: 98px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      text-align: center;
      color: #171717;
    }

    .challenges-text {
      width: 422px;
      height: 48px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #8C8C8C;
    }
  }

  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .challenges-photo-group {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 612px;
      height: 565px;
      top: 300px;

      .first-iphone {
        position: absolute;
        left: 0;
        top: 60px;
        height: 443px;
        z-index: 1;
      }

      .second-iphone {
        position: absolute;
        height: 565px;
        z-index: 2;
      }

      .third-iphone {
        position: absolute;
        right: 0;
        height: 443px;
        top: 60px;
        z-index: 1;
      }

      .line {
        position: absolute;
        transform: translateX(100%) translateY(-70px);
        animation: 20s floatText infinite linear;
      }

      @keyframes floatText {
        to {
          transform: translateX(-100%) translateY(-70px);
        }
      }

      @keyframes floatTextOtherWay {
        to {
          transform: translateX(100%) translateY(70px);
        }
      }

      .line-2 {
        position: absolute;
        transform: translateX(-100%) translateY(70px);
        animation: 20s floatTextOtherWay infinite linear;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .challenges-section {

    .wrapper {
      .challenges-photo-group {
        top: 350px;
        width: 450px;
        height: 465px;
      }

      .first-iphone {
        width: 240px;
      }

      .second-iphone {
        width: 280px;
      }

      .third-iphone {
        width: 240px;
      }
    }

    .challenges-info-block {
      width: 335px;
      //height: 0;
      left: calc(50% - 335px/2);
      .challenges-title {
        width: 335px;
        text-align: left;
        margin-bottom: 80px;
      }

      .challenges-text {
        width: 335px;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .challenges-section {
    .challenges-info-block {
      width: 90%;
      left: 5%;
    }
  }
}