/*div {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  !*box-sizing: border-box;*!*/
/*  display: block;*/
/*}*/

/*section{*/
/*    backface-visibility:hidden;*/
/*}*/

.App{
    max-width: 100vw;
    overflow: hidden;
}

@media screen and (max-width: 350px) {
    div{
       max-width: 100%;
    }

    h1{
        max-width: 100%;
    }

    h2{
        max-width: 100%;
    }

    h3{
        max-width: 100%;
    }
}